import { createApp } from 'vue';
import App from './App.vue';
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import router from './router';
import i18n from './i18n';
import store from './store';
const app = createApp(App);
app.use(ArcoVue);
app.use(router);
app.use(i18n);
// 注册 Service Worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
            console.log('ServiceWorker registered: ', registration);
        })
            .catch((error) => {
            console.error('ServiceWorker registration failed: ', error);
        });
    });
}
// Check for existing auth token
const token = localStorage.getItem('auth_token');
if (token) {
    store.commit('auth/SET_TOKEN', token);
    store.commit('auth/SET_LOGGED_IN', true);
    // You may want to add an API call here to fetch the user data using the token
}
app.use(store);
app.mount('#app');
