import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { IconLeft, IconSync, IconTranslate, IconMore, IconPlayCircle, } from '@arco-design/web-vue/es/icon';
export default defineComponent({
    name: 'FileDetail',
    components: {
        IconLeft,
        IconSync,
        IconTranslate,
        IconMore,
        IconPlayCircle,
    },
    setup() {
        const router = useRouter();
        const fileName = ref('语音包-影视作品名场面-语音包-电影-让子弹飞');
        const status = ref('processing');
        const statusText = ref('保存中');
        const currentTime = ref(99);
        const totalDuration = ref(167);
        const playbackSpeed = ref('1');
        const speakerFilter = ref('all');
        const transcriptSegments = ref([
            {
                speaker: 'Gabriel Matthews',
                time: '00:14',
                text: "No. Hey, you have your own bed. Remember how nice it is? Don't you want to? Sleep in that one. Then I will. Yeah, you can call me love a boy. They say I throw my heart around. You can be my lover girl.",
            },
            {
                speaker: 'Jonas Pike',
                time: '00:40',
                text: "I'll pick you up. I'll tell you. I fall in love. Open the door. Now everybody wants to be like us and get it down. It's going now. Oh, wow. Kill the light. No, baby. Blow your eyes away. You got me better. I.",
            },
            {
                speaker: 'Dominique Cruz',
                time: '01:12',
                text: "Gotta stay, like, a long day. You gotta love it. I might throw my heart away. You know, my heart gets. Raised. No you will ever be the same. Won't be against. The same. Light. You know, yellow is fucking me.",
            },
            {
                speaker: 'Stan Markson',
                time: '01:35',
                text: "I'll pick you up. I'll tell you. I fall in love. Open the door. Now everybody wants to be like us and get it down. It's going now. Oh, wow. Kill the light. No, baby. Blow your eyes away. You got me better. I.",
            },
        ]);
        const goBack = () => {
            router.back();
        };
        const getAvatarColor = (name) => {
            const colors = ['#FF7F50', '#6495ED', '#32CD32', '#FF4500'];
            return colors[name.charCodeAt(0) % colors.length];
        };
        const handleSliderChange = (value) => {
            currentTime.value = value;
        };
        const formatTime = (seconds) => {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.floor(seconds % 60);
            return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        };
        return {
            fileName,
            status,
            statusText,
            currentTime,
            totalDuration,
            playbackSpeed,
            speakerFilter,
            transcriptSegments,
            goBack,
            getAvatarColor,
            handleSliderChange,
            formatTime,
        };
    },
});
