<template>
  <div class="account-deletion">
    <h1>如何删除您的账户</h1>

    <div class="delete-account-section">
      <p>您可以直接在我们的移动应用中删除您的账户。请按照以下步骤操作：</p>
      <ol>
        <li>打开XTrans应用并确保您已登录</li>
        <li>点击首页左上角的三条杠图标，前往设置页面</li>
        <li>前往账户页面</li>
        <li>在账户页面中，选择"删除账户"</li>
        <li>仔细阅读删除账户的影响和注意事项</li>
        <li>点击"删除账号"按钮完成操作</li>
      </ol>

      <h2>重要提示：</h2>
      <ul>
        <li>账户删除操作是不可逆的</li>
        <li>所有与您账户相关的数据将被永久删除</li>
        <li>某些数据可能会被保留30天，以防止滥用或欺诈行为</li>
        <li>如果您有未完成的交易，请先完成或取消它们</li>
      </ul>

      <h2>删除的数据包括：</h2>
      <ul>
        <li>个人资料信息</li>
        <li>历史记录和使用数据</li>
        <li>保存的偏好设置</li>
        <li>其他与账户相关的数据</li>
      </ul>

      <p>
        如果您在删除账户过程中遇到任何问题，请通过应用内的"帮助与反馈"功能联系我们的客户支持团队。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountDeletion',
}
</script>

<style scoped>
.account-deletion {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.delete-account-section {
  margin-top: 30px;
}

h1 {
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

h2 {
  color: #444;
  margin-top: 25px;
}

ol,
ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
}

p {
  line-height: 1.6;
  color: #666;
}
</style>
