import { defineComponent, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { IconHome, IconFile, IconUser, IconMessage, IconExport, IconDown, } from '@arco-design/web-vue/es/icon';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'MainPage',
    components: {
        IconHome,
        IconFile,
        IconUser,
        IconMessage,
        IconExport,
        IconDown,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const selectedLanguage = ref('zh');
        const defaultOpenKeys = ref([]);
        const handleMenuClick = (key) => {
            switch (key) {
                case 'dashboard':
                    router.push('/main/dashboard');
                    break;
                case 'files':
                    router.push('/main/file-manager');
                    break;
                case 'feedback':
                    router.push('/main/feedback');
                    break;
                case 'notifications':
                    router.push('/main/notification-email-manage');
                    break;
                case 'account':
                    router.push('/main/account-info');
                    break;
            }
        };
        const { t, locale } = useI18n();
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        // Computed property to determine if the right sider should be shown
        const showRightSider = computed(() => {
            return ['Dashboard', 'FileManager', 'SubFolder'].includes(route.name);
        });
        return {
            selectedLanguage,
            defaultOpenKeys,
            handleMenuClick,
            currentLanguage,
            changeLanguage,
            showRightSider,
        };
    },
});
