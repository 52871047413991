import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default defineComponent({
    name: 'App',
    components: {
        Header,
        Footer,
    },
    setup() {
        const route = useRoute();
        const showHeaderFooter = computed(() => {
            return (!route.path.startsWith('/main') &&
                route.path !== '/login' &&
                route.path !== '/register' &&
                route.path !== '/forget-password');
        });
        return {
            showHeaderFooter,
        };
    },
});
