import { login as apiLogin, register } from '@/api/auth';
export const authModule = {
    namespaced: true,
    state: () => ({
        isLoggedIn: false,
        user: null,
        token: null,
    }),
    mutations: {
        /**
         * Sets the login status.
         *
         * @param state - The current state.
         * @param value - The new login status.
         */
        SET_LOGGED_IN(state, value) {
            state.isLoggedIn = value;
        },
        /**
         * Sets the user information.
         *
         * @param state - The current state.
         * @param user - The user data.
         */
        SET_USER(state, user) {
            state.user = user;
        },
        /**
         * Sets the authentication token.
         *
         * @param state - The current state.
         * @param token - The authentication token.
         */
        SET_TOKEN(state, token) {
            state.token = token;
        },
    },
    actions: {
        /**
         * Handles user login.
         *
         * @param context - The Vuex action context.
         * @param loginData - The login request payload.
         * @returns The API response.
         */
        async login({ commit }, loginData) {
            try {
                const response = await apiLogin(loginData);
                if (response.code === 0) {
                    commit('SET_TOKEN', response.data.token);
                    commit('SET_USER', response.data.userInfo);
                    commit('SET_LOGGED_IN', true);
                }
                return response;
            }
            catch (error) {
                console.error('Login error:', error);
                throw error;
            }
        },
        /**
         * Handles user registration.
         *
         * @param context - The Vuex action context.
         * @param payload - The registration payload.
         * @returns An object indicating the success status and an optional message.
         */
        async register({ commit }, payload) {
            try {
                const response = await register(payload);
                if (response.code === 0) {
                    return { success: true };
                }
                else {
                    return { success: false, message: response.msg };
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    return {
                        success: false,
                        message: error.message || 'Registration failed',
                    };
                }
                return { success: false, message: 'Registration failed' };
            }
        },
        /**
         * Handles user logout.
         *
         * @param context - The Vuex action context.
         */
        logout({ commit }) {
            commit('SET_USER', null);
            commit('SET_TOKEN', null);
            commit('SET_LOGGED_IN', false);
            localStorage.removeItem('auth_token');
        },
    },
    getters: {
        /**
         * Retrieves the login status.
         *
         * @param state - The current state.
         * @returns The login status.
         */
        isLoggedIn: (state) => state.isLoggedIn,
        /**
         * Retrieves the current user information.
         *
         * @param state - The current state.
         * @returns The user information.
         */
        currentUser: (state) => state.user,
    },
};
