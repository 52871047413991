import { defineComponent, ref, computed } from 'vue';
import { IconRefresh } from '@arco-design/web-vue/es/icon';
import { Message } from '@arco-design/web-vue';
export default defineComponent({
    name: 'FeedbackManager',
    components: { IconRefresh },
    setup() {
        const feedbacks = ref([]);
        const totalFeedbacks = ref(0);
        const currentPage = ref(1);
        const pageSize = ref(10);
        const filterStatus = ref('');
        const searchKeyword = ref('');
        const columns = [
            {
                title: '描述',
                dataIndex: 'description',
                slotName: 'description',
                width: 260,
            },
            {
                title: '反馈截图',
                dataIndex: 'screenshot',
                slotName: 'screenshot',
                width: 100,
            },
            { title: '联系方式', dataIndex: 'contact' },
            {
                title: '反馈状态',
                dataIndex: 'status',
                slotName: 'status',
                width: 100,
            },
            { title: '反馈时间', dataIndex: 'createdAt', width: 170 },
            {
                title: '操作',
                dataIndex: 'actions',
                slotName: 'actions',
                width: 80,
                align: 'left',
            },
        ];
        const getStatusColor = (status) => {
            return status === '已反馈' ? 'blue' : 'green';
        };
        const handleSubmitFeedback = () => {
            Message.info('打开提交反馈表单');
        };
        const handleSearch = () => {
            fetchFeedbacks();
        };
        const handleRefresh = () => {
            filterStatus.value = '';
            searchKeyword.value = '';
            fetchFeedbacks();
        };
        const handlePreviewChange = (visible, url) => {
            if (visible) {
                window.open(url, '_blank');
            }
        };
        const handleViewDetails = (record) => {
            Message.info(`查看反馈详情: ${record.description}`);
        };
        const handlePageChange = (page) => {
            currentPage.value = page;
            fetchFeedbacks();
        };
        const filteredFeedbacks = computed(() => {
            return feedbacks.value.filter((feedback) => (filterStatus.value === '' ||
                feedback.status === filterStatus.value) &&
                (searchKeyword.value === '' ||
                    feedback.description.includes(searchKeyword.value)));
        });
        const fetchFeedbacks = async () => {
            // 这里应该是一个实际的 API 调用
            // 为了演示，我们使用模拟数据
            const mockData = Array(pageSize.value)
                .fill(null)
                .map((_, index) => ({
                id: (currentPage.value - 1) * pageSize.value + index + 1,
                description: `账号为什么登录不了？ XXXXXXXXXX`,
                screenshot: index % 2 === 0
                    ? [
                        'https://example.com/screenshot1.jpg',
                        'https://example.com/screenshot2.jpg',
                    ]
                    : [],
                contact: index % 2 === 0 ? 'xxxxx@gmail.com' : '---',
                status: index % 2 === 0 ? '已反馈' : '已处理',
                createdAt: '2023-12-10 11:21:34',
            }));
            feedbacks.value = mockData;
            totalFeedbacks.value = 100; // 假设总共有100条反馈
        };
        fetchFeedbacks();
        return {
            feedbacks,
            columns,
            totalFeedbacks,
            currentPage,
            pageSize,
            filterStatus,
            searchKeyword,
            filteredFeedbacks,
            getStatusColor,
            handleSubmitFeedback,
            handleSearch,
            handleRefresh,
            handlePreviewChange,
            handleViewDetails,
            handlePageChange,
        };
    },
});
