import axios from './axios';
/**
 * Sends a login request to the API.
 *
 * @param data - The login request payload.
 * @returns A promise that resolves to an ApiResponse containing LoginResponse data.
 */
export const login = (data) => {
    return axios.post('/api/user/login', data);
};
/**
 * Sends a registration request to the API.
 *
 * @param data - The registration request payload.
 * @returns A promise that resolves when the registration is successful.
 */
export const register = (data) => {
    return axios.post('/auth/register', data);
};
/**
 * Requests a verification code to be sent to the specified email.
 *
 * @param email - The user's email address.
 * @returns A promise that resolves when the verification code is sent.
 */
export const getVerificationCode = (email) => {
    return axios.post('/auth/verification-code', { email });
};
