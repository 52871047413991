import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'NotificationEmailManage',
    setup() {
        // 定义通知设置的响应式数据，包含转录通知和翻译通知的开关状态
        const notificationSettings = ref({
            transcriptionNotification: true,
            translationNotification: false, // 控制翻译通知开关，默认关闭
        });
        return {
            notificationSettings, // 将通知设置暴露给模板使用
        };
    },
});
