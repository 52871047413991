import { defineComponent, ref, computed } from 'vue';
import { IconEdit } from '@arco-design/web-vue/es/icon';
import { Message } from '@arco-design/web-vue';
export default defineComponent({
    name: 'AccountInfo',
    components: { IconEdit },
    setup() {
        const userInfo = ref({
            name: '张三',
            uid: '4015649996',
            email: 'z3x***s15@gmail.com',
            notificationEmail: 'z3x***s15@gmail.com',
            password: '********',
        });
        // 计算头像文本，取名字的首个字符
        const avatarText = computed(() => {
            return userInfo.value.name.charAt(0);
        });
        // 头像的背景颜色：紫色
        const avatarBackgroundColor = '#7a52f4'; // 紫色
        const changePassword = () => {
            // 实现修改密码的逻辑
            Message.success('密码修改功能待实现');
        };
        const editName = () => {
            // 实现编辑名字的逻辑
            Message.info('编辑名字功能待实现');
        };
        return {
            userInfo,
            avatarText,
            avatarBackgroundColor,
            changePassword,
            editName,
        };
    },
});
