import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue';
import { Message } from '@arco-design/web-vue';
import { IconDown, IconEmail, IconLock } from '@arco-design/web-vue/es/icon';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'; // 引入 useRouter
export default defineComponent({
    name: 'ForgetPasswordPage',
    components: {
        IconDown,
        IconEmail,
        IconLock,
    },
    setup() {
        const router = useRouter(); // 使用 useRouter
        const { locale } = useI18n();
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const currentStep = ref(1);
        const form = reactive({
            email: '',
            verificationCode: '',
            password: '',
            confirmPassword: '',
        });
        const isCountingDown = ref(false);
        const countdown = ref(60);
        const countdownText = computed(() => {
            return isCountingDown.value
                ? `${countdown.value}s 后重新获取`
                : '获取验证码';
        });
        let timer = null;
        const getVerificationCode = () => {
            if (form.email === '') {
                Message.warning('请输入邮箱');
                return;
            }
            // TODO: 处理获取验证码的逻辑
            Message.success('验证码已发送至您的邮箱');
            // 开始倒计时
            isCountingDown.value = true;
            countdown.value = 60;
            timer = window.setInterval(() => {
                if (countdown.value > 1) {
                    countdown.value--;
                }
                else {
                    clearInterval(timer);
                    timer = null;
                    isCountingDown.value = false;
                }
            }, 1000);
        };
        const handleSubmit = () => {
            if (currentStep.value === 1) {
                if (!form.email || !form.verificationCode) {
                    Message.warning('请填写完整的信息');
                    return;
                }
                // TODO: 校验验证码是否正确
                Message.success('验证成功');
                currentStep.value = 2;
            }
            else if (currentStep.value === 2) {
                if (!form.password || !form.confirmPassword) {
                    Message.warning('请填写新密码');
                    return;
                }
                if (form.password !== form.confirmPassword) {
                    Message.error('两次输入的密码不一致');
                    return;
                }
                // TODO: 处理密码重置的逻辑，例如提交新密码到后端
                Message.success('密码重置成功');
                // 跳转到登录页面，带上邮箱参数
                router.push({
                    path: '/login',
                    query: { email: form.email },
                });
            }
        };
        const handleBack = () => {
            if (currentStep.value > 1) {
                currentStep.value--;
            }
            else {
                // 返回到登录页面
                router.push('/login');
            }
        };
        onUnmounted(() => {
            if (timer !== null) {
                clearInterval(timer);
            }
        });
        return {
            currentStep,
            form,
            isCountingDown,
            countdownText,
            getVerificationCode,
            handleSubmit,
            handleBack,
            currentLanguage,
            changeLanguage,
        };
    },
});
