import { defineComponent, ref } from 'vue';
import { IconMore } from '@arco-design/web-vue/es/icon';
import folderIcon from '@/assets/file_type_folder.png';
import videoIcon from '@/assets/file_type_video.png';
import audioIcon from '@/assets/file_type_audio.png';
import meetingIcon from '@/assets/file_type_meeting.png';
export default defineComponent({
    name: 'Dashboard',
    components: { IconMore },
    setup() {
        const recentFiles = ref([
            {
                name: '语音包-影视作品名称07:33',
                type: 'audio',
                createdAt: '2023-12-10 11:21:34',
                retention: '7天后删除',
                status: '转录中',
            },
            {
                name: '语音包-影视作品名称07:33',
                type: 'audio',
                createdAt: '2023-12-10 11:21:34',
                retention: '7天后删除',
                status: '转录失败',
            },
            {
                name: '语音包-影视作品名称07:33',
                type: 'audio',
                createdAt: '2023-12-10 11:21:34',
                retention: '7天后删除',
                status: '转录中',
            },
            {
                name: '语音包-影视作品名称07:33',
                type: 'audio',
                createdAt: '2023-12-10 11:21:34',
                retention: '7天后删除',
                status: '转录中',
            },
            {
                name: 'Google Meet在线会议07:33',
                type: 'meeting',
                createdAt: '2023-12-10 11:21:34',
                retention: '7天后删除',
                status: '转录中',
            },
            {
                name: 'Google Meet在线会议07:33',
                type: 'meeting',
                createdAt: '2023-12-10 11:21:34',
                retention: '7天后删除',
                status: '录中',
            },
        ]);
        const columns = [
            { title: '文件名称', dataIndex: 'name', slotName: 'name' },
            { title: '创建时间', dataIndex: 'createdAt' },
            { title: '保留时长', dataIndex: 'retention' },
            { title: '', dataIndex: 'actions', slotName: 'actions', width: 50 },
        ];
        const getFileIcon = (type) => {
            switch (type) {
                case 'folder':
                    return folderIcon;
                case 'video':
                    return videoIcon;
                case 'audio':
                    return audioIcon;
                case 'meeting':
                    return meetingIcon;
                default:
                    return folderIcon;
            }
        };
        const handleAction = (action, record) => {
            // Implement action handling logic here
            console.log(`Action ${action} for file:`, record.name);
        };
        return {
            recentFiles,
            columns,
            getFileIcon,
            handleAction,
        };
    },
});
