import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "app" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Header = _resolveComponent("Header");
    const _component_router_view = _resolveComponent("router-view");
    const _component_Footer = _resolveComponent("Footer");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showHeaderFooter)
            ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
            : _createCommentVNode("", true),
        _createVNode(_component_router_view),
        (_ctx.showHeaderFooter)
            ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
            : _createCommentVNode("", true)
    ]));
}
