import { createRouter, createWebHistory } from 'vue-router';
import UserAgreement from '@/components/UserAgreement.vue';
import HomePage from '../components/HomePage.vue';
import PricePage from '../components/PricePage.vue';
import DownloadPage from '../components/DownloadPage.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import DeleteAccount from '../components/DeleteAccount.vue';
import MainPage from '../components/MainPage.vue';
import Dashboard from '../pages/Dashboard.vue';
import FileManager from '../pages/FileManager.vue';
import LoginPage from '../components/LoginPage.vue';
import RegisterPage from '../components/RegisterPage.vue';
import ForgetPasswordPage from '../components/ForgetPasswordPage.vue';
import Feedback from '../pages/Feedback.vue';
import NotificationEmailManage from '../pages/NotificationEmailManage.vue';
import AccountInfo from '../pages/AccountInfo.vue';
import FileDetail from '../pages/FileDetail.vue';
import SubFolder from '../pages/SubFolder.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/pricing',
        name: 'Price',
        component: PricePage,
    },
    {
        path: '/download',
        name: 'Download',
        component: DownloadPage,
    },
    {
        path: '/user-agreement',
        name: 'UserAgreement',
        component: UserAgreement,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/delete-account',
        name: 'DeleteAccount',
        component: DeleteAccount,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/register',
        name: 'RegisterPage',
        component: RegisterPage,
    },
    {
        path: '/forget-password',
        name: 'ForgetPasswordPage',
        component: ForgetPasswordPage,
    },
    {
        path: '/main',
        name: 'Main',
        component: MainPage,
        children: [
            {
                path: '/main/dashboard',
                name: 'Dashboard',
                component: Dashboard,
            },
            {
                path: '/main/file-manager',
                name: 'FileManager',
                component: FileManager,
            },
            {
                path: '/main/file-detail',
                name: 'FileDetail',
                component: FileDetail,
            },
            {
                path: '/main/sub-folder',
                name: 'SubFolder',
                component: SubFolder,
            },
            {
                path: '/main/feedback',
                name: 'Feedback',
                component: Feedback,
            },
            {
                path: '/main/notification-email-manage',
                name: 'NotificationEmailManage',
                component: NotificationEmailManage,
            },
            {
                path: '/main/account-info',
                name: 'AccountInfo',
                component: AccountInfo,
            },
            // 可以添加一个重定向，确保进入 /main 时默认显示仪表盘
            { path: '', redirect: '/main/dashboard' },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
