import { defineComponent, reactive, computed, ref, onUnmounted } from 'vue';
import { Message } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';
import { IconDown, IconEmail, IconLock } from '@arco-design/web-vue/es/icon';
import api from '@/api';
import { useRouter } from 'vue-router';
import { getDeviceInfo } from '@/utils/device';
export default defineComponent({
    name: 'RegisterPage',
    components: {
        IconDown,
        IconEmail,
        IconLock,
    },
    setup() {
        const { locale } = useI18n();
        const router = useRouter();
        const form = reactive({
            email: '',
            verificationCode: '',
            password: '',
            confirmPassword: '',
            agreed: false,
        });
        const currentLanguage = computed(() => locale.value === 'zh' ? '简体中文' : 'English');
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const isCountingDown = ref(false);
        const countdown = ref(60);
        const countdownText = computed(() => {
            return isCountingDown.value
                ? `${countdown.value}s 后重新获取`
                : '获取验证码';
        });
        let timer = null;
        const getVerificationCode = async () => {
            if (form.email === '') {
                Message.warning('请输入邮箱');
                return;
            }
            try {
                await api.auth.getVerificationCode(form.email);
                Message.success('验证码已发送至您的邮箱');
                // Start countdown
                isCountingDown.value = true;
                countdown.value = 60;
                timer = window.setInterval(() => {
                    if (countdown.value > 1) {
                        countdown.value--;
                    }
                    else {
                        clearInterval(timer);
                        timer = null;
                        isCountingDown.value = false;
                    }
                }, 1000);
            }
            catch (error) {
                console.error('Failed to get verification code:', error);
                Message.error('获取验证码失败,请稍后重试');
            }
        };
        const handleSubmit = async () => {
            if (!form.email ||
                !form.verificationCode ||
                !form.password ||
                !form.confirmPassword) {
                Message.warning('请填写完整的注册信息');
                return;
            }
            if (form.password !== form.confirmPassword) {
                Message.error('两次输入的密码不一致');
                return;
            }
            if (!form.agreed) {
                Message.warning('请阅读并同意《用户协议》和《隐私政策》');
                return;
            }
            try {
                const deviceInfo = await getDeviceInfo();
                await api.auth.register({
                    email: form.email,
                    verificationCode: form.verificationCode,
                    password: form.password,
                    ...deviceInfo,
                });
                Message.success('注册成功');
                router.push('/login');
            }
            catch (error) {
                console.error('Registration failed:', error);
                Message.error('注册失败,请稍后重试');
            }
        };
        const openUserAgreement = () => {
            window.location.href = '/user-agreement';
        };
        const openPrivacyPolicy = () => {
            window.location.href = '/privacy-policy';
        };
        onUnmounted(() => {
            if (timer !== null) {
                clearInterval(timer);
            }
        });
        return {
            form,
            handleSubmit,
            getVerificationCode,
            currentLanguage,
            changeLanguage,
            isCountingDown,
            countdownText,
            openUserAgreement,
            openPrivacyPolicy,
        };
    },
});
