import { defineComponent, ref, onMounted } from 'vue';
import { IconMore } from '@arco-design/web-vue/es/icon';
import { Message } from '@arco-design/web-vue';
import { useRouter } from 'vue-router';
import folderIcon from '@/assets/file_type_folder.png';
import videoIcon from '@/assets/file_type_video.png';
import audioIcon from '@/assets/file_type_audio.png';
import meetingIcon from '@/assets/file_type_meeting.png';
export default defineComponent({
    name: 'FileManager',
    components: { IconMore },
    setup() {
        const router = useRouter();
        const files = ref([]);
        const totalFiles = ref(0);
        const currentPage = ref(1);
        const pageSize = ref(10);
        const columns = [
            { title: '文件名称', dataIndex: 'name', slotName: 'name' },
            { title: '创建时间', dataIndex: 'createdAt' },
            { title: '保留时长', dataIndex: 'retention' },
            { title: '操作', dataIndex: 'actions', slotName: 'actions', width: 80 },
        ];
        const getFileIcon = (type) => {
            switch (type) {
                case 'folder':
                    return folderIcon;
                case 'video':
                    return videoIcon;
                case 'audio':
                    return audioIcon;
                case 'meeting':
                    return meetingIcon;
                default:
                    return folderIcon;
            }
        };
        const getStatusColor = (status) => {
            switch (status) {
                case '转录中':
                    return 'blue';
                case '转录完成':
                    return 'green';
                case '转录失败':
                    return 'red';
                default:
                    return 'default';
            }
        };
        const handleAction = (action, record) => {
            switch (action) {
                case 'rename':
                    Message.info(`重命名文件: ${record.name}`);
                    break;
                case 'move':
                    Message.info(`移动文件: ${record.name}`);
                    break;
                case 'delete':
                    Message.warning(`删除文件: ${record.name}`);
                    break;
                default:
                    Message.info(`未知操作: ${action}`);
            }
        };
        const handlePageChange = (page) => {
            currentPage.value = page;
            fetchFiles();
        };
        const handleItemClick = (record) => {
            if (record.type === 'folder') {
                router.push({ name: 'SubFolder', params: { id: record.id } });
            }
            else {
                router.push({ name: 'FileDetail', params: { id: record.id } });
            }
        };
        const fetchFiles = async () => {
            // 模拟API调用
            const mockData = Array(pageSize.value)
                .fill(null)
                .map((_, index) => {
                const isFolder = Math.random() > 0.7; // 30% 概率是文件夹
                return {
                    id: `file-${(currentPage.value - 1) * pageSize.value + index + 1}`,
                    name: isFolder
                        ? `文件夹 ${(currentPage.value - 1) * pageSize.value + index + 1}`
                        : `文件 ${(currentPage.value - 1) * pageSize.value + index + 1}`,
                    type: isFolder
                        ? 'folder'
                        : ['video', 'audio', 'meeting'][Math.floor(Math.random() * 3)],
                    createdAt: new Date().toLocaleString(),
                    retention: isFolder ? '-' : '7天后删除',
                    status: isFolder
                        ? undefined
                        : ['转录中', '转录完成', '转录失败'][Math.floor(Math.random() * 3)],
                };
            });
            files.value = mockData;
            totalFiles.value = 100; // 假设总共有100个文件
        };
        onMounted(() => {
            fetchFiles();
        });
        return {
            files,
            columns,
            totalFiles,
            currentPage,
            pageSize,
            getFileIcon,
            getStatusColor,
            handleAction,
            handlePageChange,
            handleItemClick,
        };
    },
});
